
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'exporo-select',
})
class ExporoSelect extends Vue {
  @Prop({ default: null })
  private value?: string;

  @Prop({ default: 'exporo-label', required: true })
  private name!: string;

  @Prop({ default: false })
  private filter?: boolean;

  @Prop({ default: [], required: true })
  private options!: Array<any>;
  private filteredOptions: Array<any> = [];

  private currentInputValue: string = '';

  @Prop()
  private placeholder?: string;

  @Prop()
  private label?: string;

  @Prop()
  private id?: string;

  private isOpen: boolean = false;
  private isUpdatingOpen: boolean = false;

  @Watch('value')
  onPropertyChanged(value: string, oldValue: string) {
    this.setInputText(value);
  }

  created() {
    this.setInputText(this.value);

    document.getElementById('app').addEventListener('scroll', this.closeOptions);
  }

  destroyed() {
    document.getElementById('app').removeEventListener('scroll', this.closeOptions);
  }

  updateOptions() {
    if (!this.canFilter) {
      this.filteredOptions = this.options;

      return;
    }

    this.filteredOptions =
      this.currentInputValue != ''
        ? this.options.filter(option =>
          option.name
            .toLowerCase()
            .includes(this.currentInputValue.toLowerCase()),
        )
        : this.options;
  }

  onInputChangeValue(e: any) {
    this.currentInputValue = e.target.value;
    this.updateOptions();
  }

  onSelectValueChange(value: any) {
    this.setInputText(value);
    this.$emit('update', value);
  }

  setInputText(value: any) {
    const option = this.options.find(option => {
      return option.value === value;
    });
    this.currentInputValue = option ? option.name : value;

    this.updateOptions();
  }

  closeOptions() {
    setTimeout(() => this.isOpen = false, 100);
  }

  openOptions() {
    setTimeout(() => {
      this.isOpen = true;

      this.setOptionsPosition();
    }, 10);
  }

  setOptionsPosition() {
    setTimeout(() => {
      const select = this.$refs.select as HTMLElement;
      const position = select.getBoundingClientRect();
      const options = this.$refs.options as HTMLElement;

      options.style.top = `${Math.ceil(position.top + position.height)}px`;
      options.style.left = `${Math.ceil(position.left)}px`;
      options.style.width = `${Math.ceil(position.width)}px`;
    }, 10);
  }

  get canFilter() {
    return this.filter === true;
  }
}

export default ExporoSelect;
