
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Pagination } from '@/store/types';
import ExporoSelect from '@/components/_common/Select.vue';

interface TableItem {
  text: string;
  value: string | number | boolean;
  slotId?: string
}

@Component({
  name: 'exporo-table',
  data() {
    return {
      ascending: false,
      sortColumn: '',
      paginationOpts: [
        { name: '5', value: 5 },
        { name: '10', value: 10 },
        { name: '25', value: 25 },
        { name: '50', value: 50 },
        { name: '100', value: 100 },
      ],
    };
  },
  components: {
    ExporoSelect,
  },
})
export default class ExporoTable extends Vue {
  @Prop({ default: false, required: true })
  isLoading: Boolean;

  @Prop({ default: undefined, required: true })
  data: Array<TableItem>;

  @Prop({ default: null })
  pagination?: Pagination;

  @Prop({ default: true, required: false })
  showPagination?: Boolean;

  @Prop({ default: 'Transaktionen' })
  items?: string;

  private sortColumn: string = '';
  private currentPage: number = 1;
  private elementsPerPage: number = 25;
  private ascending: Boolean = false;
  isMobile: Boolean = window.innerWidth < 768;

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.isMobile = window.innerWidth < 768;
  }

  isAction(col) {
    return col === this.$t('transactionsList.dataTable.label', { context: 'action' });
  }

  get hasData() {
    return this.data && this.totalItems > 0;
  }

  get totalItems() {
    if (this.pagination) {
      return this.pagination.totalItems;
    }

    return this.data.length;
  }

  sortTable(col) {
    this.currentPage = 1;
    if (this.sortColumn === col && this.ascending) {
      this.ascending = false;
    }
    else if (this.sortColumn === col && !this.ascending) {
      this.ascending = true;
      this.sortColumn = null;
    } else {
      this.ascending = true;
      this.sortColumn = col;
    }

    var ascending = this.ascending;

    this.data.sort(function (a, b) {
      if (a[ col ].value > b[ col ].value) {
        return ascending ? 1 : -1;
      } else if (a[ col ].value < b[ col ].value) {
        return ascending ? -1 : 1;
      }

      return 0;
    });
  }

  get totalPages() {
    if (this.pagination && this.pagination.totalPages) {
      return this.pagination.totalPages;
    }

    return Math.ceil(this.totalItems / this.elementsPerPage);
  }

  get page() {
    if (this.pagination && this.pagination.page) {
      return this.pagination.page;
    }

    return this.currentPage;
  }

  get perPage() {
    if (this.pagination && this.pagination.perPage) {
      return this.pagination.perPage;
    }

    return this.elementsPerPage;
  }

  nextPage() {
    this.currentPage++;

    this.$emit('update', {
      page: this.page + 1,
      per_page: this.perPage,
    });
  }
  prevPage() {
    this.currentPage--;

    this.$emit('update', {
      page: this.page - 1,
      per_page: this.perPage,
    });
  }

  onSelectUpdate(value) {
    this.elementsPerPage = value;
    this.$emit('update', {
      page: this.page,
      per_page: value,
    });
  }

  sortedRows() {
    var start = (this.currentPage - 1) * this.perPage;
    var end = start + this.perPage;

    return this.data.slice(start, end);
  }

  get columns() {
    if (this.totalItems == 0) {
      return [];
    }

    return Object.keys(this.data[ 0 ]);
  }

  get gridStyle() {
    let style = 'grid-template-columns: ';
    const space = Math.round(100 / this.columns.length);
    this.columns.map(column => style += ' auto');

    return style;
  }
}

